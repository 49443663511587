import * as firebase from 'firebase';

const projectID = "nick-social-icons";
const apiKey = "AIzaSyBifbF1ojsc6GmHp4VbIoTpQZd9bwLo264";
const dbName = "nick-social-icons";

const config = {
    apiKey: apiKey,
    authDomain: `${projectID}.firebaseapp.com`,
    databaseURL: `https://${dbName}.firebaseio.com`
};

firebase.initializeApp(config);
export let firebaseDatabase = firebase.database().ref('social-icons');
