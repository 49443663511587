import React from 'react';
import {firebaseDatabase} from './firebaseDB';
import {image} from './base64Icon';
import Canvas from './canvas';


let dimensions = {
    width: 120,
    height: 120
};


function triggerDownload(imgURI, name, format) {
    let evt = new MouseEvent('click', {
        view: window,
        bubbles: false,
        cancelable: true
    });

    let a = document.createElement('a');
    a.setAttribute('download', name + '.' + format);
    a.setAttribute('href', imgURI);
    a.setAttribute('target', '_blank');

    a.dispatchEvent(evt);
}

function clickSVG(event, dd, format) {
    let canvas = document.getElementById('canvas'),
        target = event.currentTarget;
    canvas.width = dd;
    canvas.height = dd;
    let newImage = target.cloneNode(true),
        circle = newImage.getElementsByClassName(`svgCircle-${newImage.getAttribute('data-name')}`) ? newImage.getElementsByClassName(`svgCircle-${newImage.getAttribute('data-name')}`) : null,
        rect = newImage.getElementsByClassName(`svgRect-${newImage.getAttribute('data-name')}`) ? newImage.getElementsByClassName(`svgRect-${newImage.getAttribute('data-name')}`) : null,
        image = newImage.getElementsByClassName(`svgImage-${newImage.getAttribute('data-name')}`);
    newImage.height.baseVal.value = dd;
    newImage.width.baseVal.value = dd;
    if (circle.length > 0) {
        circle[0].cx.baseVal.value = dd / 2;
        circle[0].cy.baseVal.value = dd / 2;
        circle[0].r.baseVal.value = dd / 2;
    }

    if (rect.length > 0) {
        rect[0].width.baseVal.value = dd;
        rect[0].height.baseVal.value = dd
    }

    image[0].width.baseVal.value = dd;
    image[0].height.baseVal.value = dd;
    image[0].x.baseVal.value = 0;
    image[0].y.baseVal.value = 0;
    let ctx = canvas.getContext('2d'),
        data = (new XMLSerializer()).serializeToString(newImage),
        DOMURL = window.URL || window.webkitURL || window,
        name = newImage.getAttribute('data-name'),
        img = new Image(),
        svgBlob = new Blob([data], {type: 'image/svg+xml;charset=utf-8'}),
        url = DOMURL.createObjectURL(svgBlob);

    img.onload = function () {
        ctx.drawImage(img, 0, 0);
        DOMURL.revokeObjectURL(url);

        let imgURI = canvas
            .toDataURL(`image/${format}`)
            .replace(`image/${format}`, 'image/octet-stream');

        triggerDownload(imgURI, name, format);
    };

    img.src = url;
}
class UI extends React.Component {
    constructor() {
        super();
        this.state = {
            icons: [],
            downloadDimension: 3000,
            format: "png"
        }
    }

    componentWillMount() {
        firebaseDatabase.on('value', snapshot => {
            let items = snapshot.val();
            this.setState({icons: items});
        });
    };

    saveToDb() {
        let newItem = {
            color: this.state.color,
            name: this.state.name
        };
        this.state.icons.push(newItem);
        firebaseDatabase.set(this.state.icons);
    }

    render() {
        return (
            <div className="innerContainer">
                <div className="add-new-icon">
                    <div>
                        <h3>Add a new icon</h3>
                        <div className="form-container">
                            <label className="short">Name</label>
                            <input className="short" type="text" onChange={evt => this.setState({name: evt.currentTarget.value})}
                                placeholder="Name"/>
                        </div>
                        <div className="form-container">
                            <label className="short">Color</label>
                            <input className="short" type="text" onChange={evt => this.setState({color: evt.currentTarget.value})}
                            placeholder="Color"/>
                        </div>
                        <div className="form-container">
                            <button onClick={this.saveToDb.bind(this)}>Save to db</button>
                        </div>
                    </div>
                    <br/>
                    
                    <hr/>
                    <h3>Download Options</h3>
                    <div className="description">To download an icon, simply click on it.</div>
                    <br/>
                    
                    <div className="form-container">
                    <label className="long">Dimensions (square)</label>
                    <input className="long" type="number" value={this.state.downloadDimension}
                           onChange={evt => this.setState({downloadDimension: evt.currentTarget.value})}
                           placeholder="Dimensions"/>
                    </div>
                    <div className="form-container">
                        <label className="long">Icon download format</label>
                        <select className="long" value={this.state.format} onChange={evt => this.setState({ format: evt.currentTarget.value })}>
                            <option value="png">png</option>
                            <option value="jpg">jpg</option>
                            <option value="ico">ico</option>                            
                        </select>
                    </div>
                </div>
                <div className="mainContainer">
                    <div className="container">
                        <h3 className="dark">Saved Icons</h3>
                        {this.state.icons.map((item, index) => {
                            return (
                                <div key={index} className="element">
                                    <span>{item.name}</span>
                                    <svg height={dimensions.height} width={dimensions.width}
                                         onClick={(evt) => clickSVG(evt, this.state.downloadDimension, this.state.format)}
                                         data-name={item.name}>
                                        <circle cx={dimensions.width / 2} cy={dimensions.height / 2}
                                                r={dimensions.width / 2} fill={"#" + item.color}
                                                className={`svgCircle-${item.name}`}/>
                                        <image x="-5" y="-30" width={dimensions.width + 10}
                                               height={dimensions.height + 60}
                                               xlinkHref={image} className={`svgImage-${item.name}`}/>
                                    </svg>
                                    <svg height={dimensions.height} width={dimensions.width}
                                         onClick={(evt) => clickSVG(evt, this.state.downloadDimension, this.state.format)}
                                         data-name={item.name}>
                                        <rect width={dimensions.width} height={dimensions.height}
                                              fill={"#" + item.color}
                                              className={`svgRect-${item.name}`}/>
                                        <image x="-5" y="-30" width={dimensions.width + 10}
                                               height={dimensions.height + 60}
                                               xlinkHref={image} className={`svgImage-${item.name}`}/>
                                    </svg>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <Canvas/>
            </div>
        )
    }
}

export default UI;
